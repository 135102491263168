import {txtCut, utility, webkitOrNot, touchOrNot} from './lib';
import objectFitImages from 'object-fit-images';
import Player from '@vimeo/player';
const charming = require('./charming');
import {TweenMax, TimelineMax, Expo} from 'gsap';
require('intersection-observer');
const parser = require('ua-parser-js');
import PerfectScrollbar from 'perfect-scrollbar';
import Siema from 'siema';

{
    'use strict';

    utility();

    const MathUtils = {
        // map number x from range [a, b] to [c, d]
        map: (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c,
        // linear interpolation
        lerp: (a, b, n) => (1 - n) * a + n * b
    };

    const body = document.body;
    const main = document.querySelector('main');
    const preloader = document.querySelector('.preloader');
    const preloaderBg = document.querySelector('.preloader__bg');
    let globalNav;
    let hero = document.querySelector('.hero-wrap');
    let heroImage = document.querySelector('.hero-image');
    // const bgVideo = document.querySelector('.bg-video > video');
    let autoPlayVideos = document.querySelectorAll('.auto-play-video');
    let rotateContentWrap = document.querySelectorAll('.rotate-content-wrap');
    let rotateContent = document.querySelectorAll('.rotate-content');
    let rotateContentPageTitle = document.querySelector('.rotate-content__page-title');
    let rowHeader = document.querySelectorAll('.row__header-wrap');
    const btnSp = document.querySelectorAll('.btn--sp');
    const result = parser();
    const tl = new TimelineMax();
    const transformProperty = webkitOrNot();
    const isTouch = touchOrNot();
    let charms = document.querySelectorAll('.js-charming');
    let psEls = document.querySelectorAll('.ps-scroll');
    let txtCutElements = document.querySelectorAll('.js-cut');
    let ajxContTriggers = document.querySelectorAll('.js-ajx-trigger');
    let ajxPageTriggers = document.querySelectorAll('.js-ajx-page-trigger');
    let tmpdir = body.getAttribute('data-tmpdir');
    let smoothScroll;
    let slideBoard;
    let slide;
    let carousel;
    let ajaxPageLoader;
    let ps;
    let mapWrap;
    let contactForm;

    let winsize;
    const calcWinsize = () => winsize = {
        width: window.innerWidth,
        height: window.innerHeight
    };
    calcWinsize();

    let docScroll;
    const getPageYScroll = () => docScroll = window.pageYOffset || document.documentElement.scrollTop;
    window.addEventListener('scroll', getPageYScroll, {passive: true});

    // 非同期ロードコンテンツをouterHTMLに通す
    const reloadSrcsetImgs = item => {
        return new Promise(resolve => {
            let imgs = item.querySelectorAll('img');
            let videos = item.querySelectorAll('video');
            let iframes = item.querySelectorAll('iframe');

            for ( let i=0; i < imgs.length; i++ ) {
                let img = imgs[i];
                img.outerHTML = img.outerHTML;
            }

            for ( let i=0; i < videos.length; i++ ) {
                let video = videos[i];
                video.outerHTML = video.outerHTML;
            }

            for ( let i=0; i < iframes.length; i++ ) {
                let iframe = iframes[i];
                iframe.outerHTML = iframe.outerHTML;
            }

            resolve();
        });
    };

    // popstate
    if (window.history && window.history.pushState) {
        // 初回アクセス時のURLをhistoryにセット
        const _initState = {};
        _initState.url = window.location.pathname;
        window.history.replaceState(_initState, null, _initState.url);

        window.addEventListener('popstate', function(e) {
            if (!e.state) {
                return;
            }

            ajaxPageLoader.DOM.url = e.state.url;
            ajaxPageLoader.fireOnPopstate();
        });
    }

    // Google map
    mapWrap = document.getElementById('map');
    const initMap = function() {
        let latVal = parseFloat(mapWrap.getAttribute('data-lat'));
        let lngVal = parseFloat(mapWrap.getAttribute('data-lng'));
        let markerTitle = mapWrap.getAttribute('data-marker-title');
        var myLatLng = {lat: latVal, lng: lngVal};

        var map = new google.maps.Map(document.getElementById('map'), {
            center: myLatLng,
            scrollwheel: false,
            zoom: 20,
            zoomControl: true,
            disableDoubleClickZoom: true,
            mapTypeControl: false,
            scaleControl: false,
            scrollwheel: false,
            panControl: false,
            streetViewControl: false,
            styles: [{"stylers":[{"hue":"#ff1a00"},{"invert_lightness":true},{"saturation":-100},{"lightness":33},{"gamma":0.5}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#2D333C"}]}]
        });

        var icon = new google.maps.MarkerImage(tmpdir + '/images/map-marker.png',
        new google.maps.Size(97,97),
        new google.maps.Point(0,0)
        );

        var marker = new google.maps.Marker({
            map: map,
            position: {lat: latVal, lng: lngVal},
            title: markerTitle,
            icon: icon
        });

        google.maps.event.addDomListener(window, "resize", function() {
            var center = map.getCenter();
            google.maps.event.trigger(map, "resize");
            map.setCenter(center);
        });
    }

    class AjaxPageLoader {
        constructor(els) {
            this.DOM = {
                triggerList: els
            }

            this.state = {};
            this.DOM.wrap = document.querySelector('.scroll-wrap');

            for ( const el of this.DOM.triggerList ) {
                el.addEventListener('click', this.fire.bind(this));
            }
        }

        fireOnPopstate() {
            const self = this;

            tl.to('.preloader', 0, {
                display: 'block'
            }).to(preloaderBg, 1.2, {
                x: '0%',
                ease: Expo.easeInOut,
                onComplete: () => {
                    self.delete();
                    globalNav.clickCloseHandler();
                    document.documentElement.scrollTop = 0;

                    self.processing(self);
                }
            });
        }

        fire(e) {
            e.preventDefault();
            e.stopPropagation();

            const self = this;

            // URLを取得
            this.DOM.url = e.currentTarget.getAttribute('href');
            this.state.url = this.DOM.url;

            tl.to('.preloader', 0, {
                display: 'block'
            }).to(preloaderBg, 1.2, {
                x: '0%',
                ease: Expo.easeInOut,
                onComplete: () => {
                    document.documentElement.scrollTop = 0;
                    self.delete();
                    globalNav.clickCloseHandler();

                    window.history.scrollRestoration = 'manual'; // popstateの際にスクロール位置に戻さないようにする
                    window.history.pushState(self.state, null, self.DOM.url);

                    self.processing(self);
                }
            });
        }

        processing(self) {
            let xhr = new XMLHttpRequest();
            if (xhr) {
                try {
                    xhr.open('GET', self.DOM.url, true);
                    xhr.responseType = 'document';
                    if (xhr.overrideMimeType) {
                        xhr.overrideMimeType('text/html;charset=utf-8');
                    }
                    xhr.onreadystatechange = function() {
                        if (this.readyState === 4 && this.status === 200) {
                            try {
                                console.log('PPPPP', this.response);

                                self.DOM.response = this.response;

                                self.callback(self);
                            } catch (e) {
                                throw new Error([e.name, e.message].join(' : '));
                            }
                        }
                    };
                    xhr.timeout = 15000;
                    xhr.ontimeout = function(e) {
                        throw new Error([e.name, e.message].join(' : '));
                    };
                    xhr.onerror = function(e) {
                        throw new Error([e.name, e.message].join(' : '));
                    };
                    xhr.send();
                } catch (e) {
                    throw new Error([e.name, e.message].join(' : '));
                }
            }
        }

        delete() {
            const _scrollable = document.querySelector('div[data-scroll]');
            const _nav = document.querySelector('.nav');
            const _videoModal = document.querySelector('.video-modal');
            const _galleryModal = document.querySelector('.gallery-modal');


            if ( _scrollable ) {
                _scrollable.parentNode.removeChild(_scrollable);
            }
            if ( _nav ) {
                _nav.parentNode.removeChild(_nav);
            }
            if ( _videoModal ) {
                _videoModal.parentNode.removeChild(_videoModal);
            }
            if ( _galleryModal ) {
                _galleryModal.parentNode.removeChild(_galleryModal);
            }
        }

        append(response) {
            return new Promise(resolve => {
                const _scrollable = response.querySelector('div[data-scroll]');
                const _nav = response.querySelector('.nav');
                const _videoModal = response.querySelector('.video-modal');
                const _galleryModal = response.querySelector('.gallery-modal');

                if ( _scrollable ) {
                    main.appendChild( _scrollable );
                }
                if ( _nav ) {
                    body.appendChild( _nav );
                }
                if ( _videoModal ) {
                    body.appendChild( _videoModal );
                }
                if ( _galleryModal ) {
                    body.appendChild( _galleryModal );
                }

                console.log('ajax appended');

                resolve();
            });
        }

        preload() {
            return Promise.all([preloadImages(), preloadVideo(), preloadEvents()]).then(() => {
                return true;
            });
        }

        init() {
            return new Promise(resolve => {
                charms = document.querySelectorAll('.js-charming');
                txtCutElements = document.querySelectorAll('.js-cut');

                // Google map
                mapWrap = document.getElementById('map');
                if (mapWrap) {
                    initMap();
                }

                // Contact Form 7をinit
                contactForm = document.querySelectorAll('.wpcf7 > form');
                if (contactForm) {
                    contactForm.forEach((el) => {
                        wpcf7.initForm( el );

                        if ( wpcf7.cached ) {
                            wpcf7.refill( el );
                        }
                    });
                }

                // 動的レイアウト
                layout();

                // Charming
                for ( const el of charms ) {
                    charming(el);
                }

                /*
                 * テキストを短く
                 * single.phpのパンくずリスト
                 */
                for ( const el of txtCutElements ) {
                    el.textContent = txtCut(el.textContent, 24, '...', '');
                }

                getPageYScroll();

                // if (!isTouch) {
                //     smoothScroll = new SmoothScroll();
                // }

                const videoModal = new VideoModal();
                videoModal.init();

                // if (bgVideo) {
                //     bgVideo.play();
                // }

                console.log('ajax init');

                resolve();
            });
        }

        callback(self) {
            const classes = self.DOM.response.querySelector('body').getAttribute('class');
            const pageTitle = self.DOM.response.querySelector('body').getAttribute('data-page-title');

            body.setAttribute('class', classes);
            body.setAttribute('data-page-title', pageTitle);
            rotateContentPageTitle.textContent = pageTitle;

            self.append( self.DOM.response ).then(() => {
                // 非同期ロードコンテンツをouterHTMLに通す ※append後にこれをやってからじゃないとセレクタが古いほうの要素を取ってきてしまう
                return reloadSrcsetImgs(document.body);
            }).then(() => {
                return self.preload();
            }).then(() => {
                return self.init();
            }).then(() => {
                const waveLines = document.querySelectorAll('.hero__title .js-wave-line span');

                // tl.to(preloaderBg, 1.2, {
                //     x: '100%',
                //     ease: Expo.easeInOut,
                //     onUpdate: () => {
                //         if ( tl._last._time / tl._last._duration > 0.3 ) {
                //             TweenMax.staggerTo(waveLines, 1.2, {
                //                 y: 0,
                //                 ease: Expo.easeOut
                //             }, 0.05);
                //         }
                //
                //         if ( tl._last._time / tl._last._duration > 0.5 ) {
                //             [...document.querySelectorAll('.plx')].forEach(item => observer.observe(item));
                //             [...document.querySelectorAll('.gallery__item')].forEach(item => observer.observe(item));
                //         }
                //     },
                //     onComplete: () => {
                //         TweenMax.set(preloaderBg, {
                //             x: '-100%'
                //         });
                //         preloader.style.display = 'none';
                //
                //         self.DOM.response = null;
                //     }
                // });

                setTimeout(() => {
                    tl.to(preloaderBg, 1.2, {
                        x: '100%',
                        ease: Expo.easeInOut,
                        onUpdate: () => {
                            if ( tl._last._time / tl._last._duration > 0.3 ) {
                                TweenMax.staggerTo(waveLines, 1.2, {
                                    y: 0,
                                    ease: Expo.easeOut
                                }, 0.05);
                            }

                            if ( tl._last._time / tl._last._duration > 0.5 ) {
                                [...document.querySelectorAll('.plx')].forEach(item => observer.observe(item));
                                [...document.querySelectorAll('.gallery__item')].forEach(item => observer.observe(item));

                                if (autoPlayVideos[0]) {
                                    for (const el of autoPlayVideos) {
                                        el.play();
                                    }
                                }
                            }
                        },
                        onComplete: () => {
                            TweenMax.set(preloaderBg, {
                                x: '-100%'
                            });
                            preloader.style.display = 'none';

                            self.DOM.response = null;
                        }
                    });
                }, 1000);

            });
        }
    }

    class AjaxContentLoader {
        constructor(el) {
            this.DOM = {
                trigger: el
            }
            this.DOM.wrapClassName = this.DOM.trigger.getAttribute('data-wrap-class');
            this.DOM.wrapEl = document.querySelector(this.DOM.wrapClassName);
            this.DOM.itemsClassName = this.DOM.trigger.getAttribute('data-items-class');
            this.DOM.itemsEl = this.DOM.wrapEl.querySelector(this.DOM.itemsClassName);
            this.DOM.itemClassName = this.DOM.trigger.getAttribute('data-item-class');
            this.DOM.triggerClassName = this.DOM.trigger.getAttribute('data-trigger-class');
            this.DOM.txtEl = this.DOM.trigger.querySelector(this.DOM.trigger.getAttribute('data-load-txt-class'));
            this.DOM.trigger.addEventListener('click', this.clickLoadHandler.bind(this));

            TweenMax.set(this.DOM.txtEl.children[0], {
                opacity: 1,
                skewY: '0deg'
            });

            TweenMax.set(this.DOM.txtEl.children[1], {
                opacity: 0,
                skewY: '7deg'
            });
        }

        clickLoadHandler(e) {
            e.preventDefault();
            e.stopPropagation();

            // 次ページの番号を取得
            this.DOM.naxtPageNum = parseInt(this.DOM.trigger.getAttribute('data-current-page-num')) + 1;

            // URLを取得
            this.DOM.url = e.currentTarget.getAttribute('href');

            TweenMax.to(this.DOM.txtEl.children[0], 0.5, {
                opacity: 0,
                skewY: '7deg',
                ease: Expo.easeInOut
            });

            TweenMax.to(this.DOM.txtEl.children[1], 0.5, {
                opacity: 1,
                skewY: '0deg',
                ease: Expo.easeInOut
            });

            // ボタンの状態を変更
            TweenMax.staggerTo(this.DOM.txtEl.children, 0.5, {
                y: '-50%',
                ease: Expo.easeInOut,
            }, 0, this.processing(this));
        }

        processing(self) {
            let xhr = new XMLHttpRequest();
            if (xhr) {
                try {
                    xhr.open('GET', self.DOM.url, true);
                    xhr.responseType = 'document';
                    if (xhr.overrideMimeType) {
                        xhr.overrideMimeType('text/html;charset=utf-8');
                    }
                    xhr.onreadystatechange = function() {
                        if (this.readyState === 4 && this.status === 200) {
                            try {
                                self.DOM.response = this.response.querySelector( self.DOM.wrapClassName );
                                self.callback(self);
                            } catch (e) {
                                throw new Error([e.name, e.message].join(' : '));
                            }
                        }
                    };
                    xhr.timeout = 15000;
                    xhr.ontimeout = function(e) {
                        throw new Error([e.name, e.message].join(' : '));
                    };
                    xhr.onerror = function(e) {
                        throw new Error([e.name, e.message].join(' : '));
                    };
                    xhr.send();
                } catch (e) {
                    throw new Error([e.name, e.message].join(' : '));
                }
            }
        }

        callback(self) {
            const nextAnchor = self.DOM.response.querySelector(self.DOM.triggerClassName);
            const newItems = self.DOM.response.querySelectorAll(self.DOM.itemClassName);

            // トリガーURLを更新
            if ( nextAnchor ) {
                self.DOM.trigger.setAttribute('href', nextAnchor.getAttribute('href'));
            } else {
                self.DOM.trigger.parentNode.removeChild(self.DOM.trigger);
            }

            [...newItems].forEach(el => {
                TweenMax.set(el, {
                    y: '100%',
                    skewY: '3deg',
                    opacity: 0
                });
                self.DOM.itemsEl.appendChild(el);
            });

            // DOMへ追加してからでないと適用できないイベント等を適用
            const appendedItems = document.querySelectorAll('.news-lead__item--' + self.DOM.naxtPageNum);

            new AjaxPageLoader( document.querySelectorAll('.news-lead__item--' + self.DOM.naxtPageNum +' .js-ajx-page-trigger') );

            imagesLoaded(appendedItems, () => {
                // ie対策
                [...appendedItems].forEach(item => {
                    objectFitImages(item.querySelector('img'));
                });

                TweenMax.staggerTo(appendedItems, 1, {
                    y: '0%',
                    skewY: '0deg',
                    opacity: 1,
                    ease: Expo.easeInOut
                }, 0.05);
            });

            // ボタンの状態を変更
            TweenMax.staggerTo(this.DOM.txtEl.children, 0.5, {
                y: '50%',
                ease: Expo.easeInOut
            }, 0);

            TweenMax.to(this.DOM.txtEl.children[0], 0.5, {
                opacity: 1,
                skewY: '0deg',
                ease: Expo.easeInOut
            });

            TweenMax.to(this.DOM.txtEl.children[1], 0.5, {
                opacity: 0,
                skewY: '7deg',
                ease: Expo.easeInOut
            });

            // smoothScroll.setSize();
            self.DOM.response = null;
        }
    }

    class GalleryAjaxLoader {
        constructor(el) {
            this.DOM = {
                el: el
            }
            this.DOM.container = document.querySelector('.gallery');
            this.DOM.el.addEventListener('click', this.clickLoadHandler.bind(this));
        }

        clickLoadHandler(e) {
            e.preventDefault();
            e.stopPropagation();

            // 新しく読み込むアイテムのページ番号を取得
            this.DOM.pageNum = parseInt(e.currentTarget.firstChild.getAttribute('data-page-num')) + 1;

            // ボタンの状態を変更
            e.currentTarget.textContent = null;
            e.currentTarget.innerHTML = '<div class="gallery__pagination__txt">Loading...</div>';

            this.DOM.url = e.currentTarget.getAttribute('href');

            this.processing(this);
        }

        processing(self) {
            let xhr = new XMLHttpRequest();
            if (xhr) {
                try {
                    xhr.open('GET', self.DOM.url, true);
                    xhr.responseType = 'document';
                    if (xhr.overrideMimeType) {
                        xhr.overrideMimeType('text/html;charset=utf-8');
                    }
                    xhr.onreadystatechange = function() {
                        if (this.readyState === 4 && this.status === 200) {
                            try {
                                self.DOM.response = this.response.querySelector('.gallery');
                                self.callback(self);
                            } catch (e) {
                                throw new Error([e.name, e.message].join(' : '));
                            }
                        }
                    };
                    xhr.timeout = 15000;
                    xhr.ontimeout = function(e) {
                        throw new Error([e.name, e.message].join(' : '));
                    };
                    xhr.onerror = function(e) {
                        throw new Error([e.name, e.message].join(' : '));
                    };
                    xhr.send();
                } catch (e) {
                    throw new Error([e.name, e.message].join(' : '));
                }
            }
        }

        callback(self) {
            let newPagination = self.DOM.response.querySelector('.gallery__pagination');
            let nextAnchor = newPagination.querySelector('a');
            let pagination = document.querySelector('.gallery__pagination');

            // 古いページネーションを削除
            pagination.parentNode.removeChild(pagination);

            [...self.DOM.response.querySelectorAll('.gallery__item')].forEach(el => {
                new Gallery(el);
                self.DOM.container.appendChild(el);
            });

            // DOMへ追加してからでないと適用できないイベント等を適用
            [...document.querySelectorAll('.gallery__item--' + self.DOM.pageNum)].forEach(item => {
                let _img = item.querySelector('img');

                imagesLoaded(_img, () => {
                    // 新しく読み込んだアイテムを監視
                    observer.observe(item);

                    // ie対策
                    objectFitImages(_img);
                });
            });

            // 新しいページネーションを追加
            if (nextAnchor) {
                nextAnchor.addEventListener('click', self.clickLoadHandler.bind(self));
                self.DOM.container.appendChild(newPagination);
            }

            // smoothScroll.setSize();
            self.DOM.response = null;
        }
    }

    class Gallery {
        constructor(el) {
            this.DOM = {
                el: el
            }
            this.DOM.image = {
                s: this.DOM.el.getAttribute('data-image-s'),
                m: this.DOM.el.getAttribute('data-image-m'),
                l: this.DOM.el.getAttribute('data-image-l'),
                xl: this.DOM.el.getAttribute('data-image-xl')
            }
            this.DOM.srcset = {
                s: this.DOM.el.getAttribute('data-image-s') + ' 500w',
                m: this.DOM.el.getAttribute('data-image-m') + ' 1000w',
                l: this.DOM.el.getAttribute('data-image-l') + ' 1500w',
                xl: this.DOM.el.getAttribute('data-image-xl') + ' 2000w'
            }
            this.createSrcset = [];
            this.DOM.imgContainer = document.querySelector('.gallery-modal__image');
            this.DOM.captionContainer = document.querySelector('.gallery-modal__caption');
            this.DOM.content = document.createElement('img');
            this.DOM.caption = this.DOM.el.getAttribute('data-caption');
            this.DOM.closeBtn = document.querySelector('.gallery-modal__close-btn');
            this.srcset = [];

            Object.keys(this.DOM.srcset).forEach((key, i) => {
                if ( this.DOM.srcset[key] ) {
                    this.srcset.push(this.DOM.srcset[key]);
                }
            });

            this.DOM.content.setAttribute('src', this.DOM.image.s);
            this.DOM.content.setAttribute('srcset', this.srcset.join(','));
            this.DOM.content.setAttribute('alt', this.DOM.caption);
            this.tl = new TimelineMax();

            this.initEvent();
        }

        initEvent() {
            this.DOM.el.addEventListener('click', this.clickOpenHandler.bind(this));
            this.DOM.closeBtn.addEventListener('click', this.clickCloseHandler.bind(this));
        }

        clickOpenHandler(e) {
            this.DOM.imgContainer.appendChild(this.DOM.content);
            this.DOM.captionContainer.textContent = this.DOM.caption;
            this.modalOpen();
        }

        clickCloseHandler(e) {
            document.querySelector('.gallery-modal__image').textContent = null;
            document.querySelector('.gallery-modal__caption').textContent = null;
            this.modalClose();
        }

        modalOpen() {
            this.tl.to([main, '.fixed-el'], 0.3, {
                opacity: 0,
                ease: Expo.easeOut
            }).to([main, '.fixed-el'], 0, {
                visibility: 'hidden'
            }).to('.gallery-modal', 0, {
                visibility: 'visible'
            }).to('.gallery-modal', 0.3, {
                opacity: 1,
                ease: Expo.easeOut
            });
        }

        modalClose() {
            this.tl.to('.gallery-modal', 0.3, {
                opacity: 0,
                ease: Expo.easeOut
            }).to('.gallery-modal', 0, {
                visibility: 'hidden'
            }).to([main, '.fixed-el'], 0, {
                visibility: 'visible'
            }).to([main, '.fixed-el'], 0.3, {
                opacity: 1,
                ease: Expo.easeOut
            });
        }
    }

    class Item {
        constructor(el) {
            this.DOM = {
                el: el
            }
            this.DOM.image = this.DOM.el.querySelector('.plx__img');
            this.renderedStyles = {
                innerTranslationY: {
                    previous: 0,
                    current: 0,
                    ease: 0.1,
                    maxValue: parseInt(getComputedStyle(this.DOM.image).getPropertyValue('--overflow'), 10),
                    setValue: () => {
                        const maxValue = this.renderedStyles.innerTranslationY.maxValue;
                        const minValue = -1 * maxValue;
                        return Math.max(Math.min(MathUtils.map(this.props.top - docScroll, winsize.height, -1 * this.props.height, minValue, maxValue), maxValue), minValue)
                    }
                }
            };

            this.update();
            this.observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    this.isVisible = entry.intersectionRatio > 0;
                });
            });
            this.observer.observe(this.DOM.el);
            this.initEvents();
        }

        update() {
            this.getSize();
            for (const key in this.renderedStyles) {
                this.renderedStyles[key].current = this.renderedStyles[key].previous = this.renderedStyles[key].setValue();
            }
            this.layout();
        }

        getSize() {
            const rect = this.DOM.el.getBoundingClientRect();
            this.props = {
                height: rect.height,
                top: docScroll + rect.top
            }
        }

        initEvents() {
            if (isTouch) {
                window.addEventListener('orientationchange', this.resize());
            } else {
                window.addEventListener('resize', this.resize());
            }
        }

        resize() {
            this.update();
        }

        render() {
            for (const key in this.renderedStyles) {
                this.renderedStyles[key].current = this.renderedStyles[key].setValue();
                this.renderedStyles[key].previous = MathUtils.lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].ease);
            }

            this.layout();
        }

        layout() {
            this.DOM.image.style.transform = `translate3d(0, ${this.renderedStyles.innerTranslationY.previous}px, 0)`;
        }
    }

    class SmoothScroll {
        constructor() {
            this.DOM = {
                main: document.querySelector('main')
            };
            this.DOM.scrollable = this.DOM.main.querySelector('div[data-scroll]');
            this.items = [];
            [...this.DOM.main.querySelectorAll('.plx')].forEach(item => this.items.push(new Item(item)));
            this.renderedStyles = {
                translationY: {
                    previous: 0,
                    current: 0,
                    ease: 0.1,
                    setValue: () => docScroll
                }
            };

            this.setSize();
            this.update();
            this.style();
            // this.initEvents();
            requestAnimationFrame(() => this.render());
        }

        update() {
            for (const key in this.renderedStyles) {
                this.renderedStyles[key].current = this.renderedStyles[key].previous = this.renderedStyles[key].setValue();
            }
            this.layout();
        }

        layout() {
            this.DOM.scrollable.style.transform = `translate3d(0, ${-1*this.renderedStyles.translationY.previous}px, 0)`;
        }

        setSize() {
            body.style.height = `${this.DOM.scrollable.scrollHeight}px`;
        }

        style() {
            this.DOM.main.style.position = 'fixed';
            this.DOM.main.style.width = this.DOM.main.style.height = '100%';
            this.DOM.main.style.top = this.DOM.main.style.left = 0;
            this.DOM.main.style.overflow = 'hidden';
        }

        initEvents() {
            if (isTouch) {
                window.addEventListener('orientationchange', this.setSize());
            } else {
                window.addEventListener('resize', this.setSize());
            }
        }

        render() {
            for (const key in this.renderedStyles) {
                this.renderedStyles[key].current = this.renderedStyles[key].setValue();
                this.renderedStyles[key].previous = MathUtils.lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].ease);
            }

            this.layout();

            for (const item of this.items) {
                if (item.isVisible) {
                    item.render();
                }
            }

            requestAnimationFrame(() => this.render());
        }
    }

    class GlobalNav {
        constructor() {
            this.openBtns = document.querySelectorAll('.nav-btn');
            if (!this.openBtns[0]) {
                return;
            }

            this.closeBtn = document.querySelector('.nav__close-btn');
            this.waveLines = document.querySelectorAll('.nav__option .js-wave-line span');
        }

        init() {
            this.clickOpenHandler = this.open.bind(this);
            this.clickCloseHandler = this.close.bind(this);

            for ( let i = 0, len = this.openBtns.length; i < len; i++ ) {
                this.openBtns[i].addEventListener('click', this.clickOpenHandler);
            }

            this.closeBtn.addEventListener('click', this.clickCloseHandler);
        }

        destroy() {
            removeEventListener('click', this.clickOpenHandler);
            removeEventListener('click', this.clickCloseHandler);
        }

        open() {
            // if (bgVideo) {
            //     bgVideo.pause();
            // }
            if (autoPlayVideos[0]) {
                for (const el of autoPlayVideos) {
                    el.pause();
                }
            }

            tl.to([main, '.fixed-el'], 0.3, {
                opacity: 0,
                ease: Expo.easeOut
            }).to([main, '.fixed-el'], 0, {
                visibility: 'hidden'
            }).to('.nav', 0, {
                visibility: 'visible'
            }).to('.nav', 0.3, {
                opacity: 1,
                ease: Expo.easeOut
            }).staggerTo(this.waveLines, 0.8, {
                y: 0,
                ease: Expo.easeOut
            }, 0.05, '-=0.4');
        }

        close() {
            // if (bgVideo) {
            //     bgVideo.play();
            // }
            if (autoPlayVideos[0]) {
                for (const el of autoPlayVideos) {
                    el.play();
                }
            }

            tl.to('.nav', 0.3, {
                opacity: 0,
                ease: Expo.easeOut
            }).to('.nav', 0, {
                visibility: 'hidden'
            }).to([main, '.fixed-el'], 0, {
                visibility: 'visible'
            }).to([main, '.fixed-el'], 0.3, {
                opacity: 1,
                ease: Expo.easeOut
            }).staggerTo(this.waveLines, 0, {
                y: 100
            }, 0);
        }
    }

    class StickyHeader {
        constructor() {
            this.DOM = {
                el: document.querySelector('.site-header')
            };
            if (!this.DOM.el) {
                return;
            }

            window.addEventListener('scroll', this.scrollHandler.bind(this), {passive: true});
            this.startPos = 0;
        }

        scrollHandler() {
            this.scrollTop = window.pageYOffset;

            if ( this.scrollTop > winsize.height ) {
                if ( this.scrollTop < this.startPos ) {
                    body.addClass('header-is-show');
                } else {
                    body.removeClass('header-is-show');
                }
                // this.DOM.el.style[transformProperty] = 'translate3d(0, '+ this.scrollTop * -1 +'px, 0)';
            }
            else {
                body.removeClass('header-is-show');
            }

            this.startPos = this.scrollTop;
        }
    }

    // モーダル
    class VideoModal {
        constructor() {
            this.modal = document.querySelector('.video-modal');
            if (!this.modal) {
                return;
            }
            this.openBtns = document.querySelectorAll('.video-modal__open-btn');
            this.closeBtns = document.querySelectorAll('.video-modal__close-btn');

            this.videoOptions = {
                id: '373405770',
                autoplay: true,
                loop: false,
                byline: false,
                title: false,
                muted: false
            };
            this.videoWrap = document.querySelector('.video-player');
        }

        init() {
            if (!this.modal) {
                return;
            }

            this.openBtns.forEach((el) => {
                // Videoモーダルだったら
                if (el.getAttribute('data-modal-type') === 'video') {
                    el.addEventListener('click', () => {
                        this.modalOpen();
                        this.player = new Player(this.videoWrap, this.videoOptions);
                        this.player.play();
                        this.modal.setAttribute('data-modal', 'video');
                        // bgVideo.pause();
                        if (autoPlayVideos[0]) {
                            for (const el of autoPlayVideos) {
                                el.pause();
                            }
                        }
                    });
                }
            });

            this.closeBtns.forEach((el) => {
                el.addEventListener('click', () => {
                    this.modalClose();

                    if (this.modal.getAttribute('data-modal') === 'video') {
                        this.player.destroy();
                        // bgVideo.play();
                        if (autoPlayVideos[0]) {
                            for (const el of autoPlayVideos) {
                                el.play();
                            }
                        }
                    }

                    this.modal.setAttribute('data-modal', '');
                });
            });
        }

        modalOpen() {
            tl.to([main, '.fixed-el'], 0.3, {
                opacity: 0,
                ease: Expo.easeOut
            }).to([main, '.fixed-el'], 0, {
                visibility: 'hidden'
            }).to('.video-modal', 0, {
                visibility: 'visible'
            }).to('.video-modal', 0.3, {
                opacity: 1,
                ease: Expo.easeOut
            });
        }

        modalClose() {
            tl.to('.video-modal', 0.3, {
                opacity: 0,
                ease: Expo.easeOut
            }).to('.video-modal', 0, {
                visibility: 'hidden'
            }).to([main, '.fixed-el'], 0, {
                visibility: 'visible'
            }).to([main, '.fixed-el'], 0.3, {
                opacity: 1,
                ease: Expo.easeOut
            });
        }
    }

    class SlideBoard {
        constructor(el) {
            this.DOM = {
                el: el
            };
            this.DOM.duration = parseInt(el.getAttribute('data-duration'));
            this.DOM.items = this.DOM.el.children;
            this.setSize();
            this.initEvents();
        }

        initEvents() {
            if (isTouch) {
                window.addEventListener('orientationchange', this.setSize());
            } else {
                window.addEventListener('resize', this.setSize());
            }
        }

        setSize() {
            this.DOM.itemWidth = this.DOM.items[0].clientWidth;
            this.DOM.elWidth = this.DOM.itemWidth * this.DOM.items.length;
            this.DOM.el.style.left = -this.DOM.itemWidth + 'px';
            this.move();
        }

        move() {
            const self = this;

            TweenMax.set(this.DOM.items, {
                x: function(i) {
                    return i * self.DOM.itemWidth;
                }
            });

            TweenMax.to(this.DOM.items, this.DOM.duration, {
                ease: Linear.easeNone,
                x: '-='+self.DOM.elWidth,
                modifiers: {
                    x: function(x) {
                        return x < 0 ? (x % self.DOM.elWidth) + self.DOM.elWidth : x;
                    }
                },
                repeat: -1
            });
        }
    }

    // 画像 読み込み
    const preloadImages = () => {
        return new Promise((resolve, reject) => {
            imagesLoaded(document.querySelectorAll('img'), () => {
                objectFitImages('img.object-fit-cover'); // IE対策

                // Slide
                if ( document.querySelectorAll('.slide')[0] ) {
                    slide = new Siema({
                        selector: '.slide',
                        duration: 1000,
                        easing: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
                        draggable: true,
                        threshold: 20,
                        loop: true,
                        onInit: () => {
                            // overflow:visibleを強制しているため、カルーセルコンテナに高さを動的に付与
                            // document.querySelectorAll('.slide')[0].style.height = document.querySelectorAll('.plx__img-wrap')[0].clientHeight + 'px';

                        },
                        onChange: () => {},
                    });

                    setInterval(() => slide.next(), 5000);
                }

                // Carousel
                if ( document.querySelectorAll('.carousel')[0] ) {
                    if (winsize.width >= 768) {
                        carousel = new Siema({
                            selector: '.carousel',
                            duration: 200,
                            easing: 'ease-out',
                            perPage: 3,
                            startIndex: 0,
                            draggable: true,
                            multipleDrag: true,
                            threshold: 20,
                            loop: false,
                            rtl: false,
                            onInit: () => {
                                // overflow:visibleを強制しているため、カルーセルコンテナに高さを動的に付与
                                document.querySelectorAll('.carousel')[0].style.height = document.querySelectorAll('.services-lead__main__item')[0].clientHeight + 'px';
                            },
                            onChange: () => {},
                        });
                    } else {
                        carousel = new Siema({
                            selector: '.carousel',
                            duration: 200,
                            easing: 'ease-out',
                            perPage: 1,
                            startIndex: 0,
                            draggable: true,
                            multipleDrag: true,
                            threshold: 20,
                            loop: false,
                            rtl: false,
                            onInit: () => {
                                // overflow:visibleを強制しているため、カルーセルコンテナに高さを動的に付与
                                document.querySelectorAll('.carousel')[0].style.height = document.querySelectorAll('.services-lead__main__item')[0].clientHeight + 'px';
                            },
                            onChange: () => {},
                        });
                    }
                }

                console.log('images loaded');
                resolve();
            });
        });
    };

    // ページ 読み込み
    const preloadPage = () => {
        return new Promise((resolve, reject) => {
            window.addEventListener('load', () => {
                console.log('page loaded');
                resolve();
            });
        });
    };

    // フォント 読み込み
    const preloadFonts = () => {
        return new Promise((resolve, reject) => {
            (function(d) {
                var config = {
                        kitId: 'xxs0lrn',
                        scriptTimeout: 3000,
                        async: true,
                        active: function() {
                            console.log('font loaded');
                            resolve();
                        }
                    },
                    h = d.documentElement,
                    t = setTimeout(function() {
                        h.className = h.className.replace(/\bwf-loading\b/g, "") + " wf-inactive";
                    }, config.scriptTimeout),
                    tk = d.createElement("script"),
                    f = false,
                    s = d.getElementsByTagName("script")[0],
                    a;
                h.className += " wf-loading";
                tk.src = 'https://use.typekit.net/' + config.kitId + '.js';
                tk.async = true;
                tk.onload = tk.onreadystatechange = function() {
                    a = this.readyState;
                    if (f || a && a != "complete" && a != "loaded") return;
                    f = true;
                    clearTimeout(t);
                    try {
                        Typekit.load(config);
                    } catch (e) {}
                };
                s.parentNode.insertBefore(tk, s)
            })(document);
        });
    };

    // レイアウト
    const layout = () => {
        hero = document.querySelector('.hero-wrap');
        heroImage = document.querySelector('.hero-image');
        rotateContentWrap = document.querySelectorAll('.rotate-content-wrap');
        rotateContent = document.querySelectorAll('.rotate-content');
        rowHeader = document.querySelectorAll('.row__header-wrap');
        calcWinsize();

        // Slide Board
        if ( document.querySelector('.slide-board') ) {
            slideBoard = new SlideBoard(document.querySelector('.slide-board'));
        }

        // Instagram feed layout
        const _instafeed__main__item = document.querySelectorAll('.instafeed__main__item')[0];
        if ( _instafeed__main__item ) {
            document.querySelector('.instafeed__main-wrap').style.height = _instafeed__main__item.clientHeight + 'px';
        }

        // Hero element
        if ( hero.hasClass('hero-wrap--sp') ) {
            // hero.style.height = winsize.height + 'px'; // mobile safari対策
        }

        // Hero Image
        if ( heroImage ) {
            if (result.device.type !== 'mobile') {
                // heroImage.style.height = winsize.height * 0.8955 + 'px'; // mobile safari対策
            }
            else {
                // heroImage.style.height = winsize.height * 0.88 + 'px'; // mobile safari対策
            }
        }

        // Rotate Content
        for (let i = 0, len = rotateContentWrap.length; i < len; i++) {
            rotateContentWrap[i].style.width = winsize.height * 0.5 + 'px'; // mobile safari対策
            rotateContent[i].style.width = winsize.height * 0.5 + 'px'; // mobile safari対策
        }

        for (let i = 0, len = rowHeader.length; i < len; i++) {
            rowHeader[i].style.marginTop = rowHeader[i].clientHeight * 0.5 * -1 + 'px';

            if ( i === 0 ) {
                // HomeまたはPlacesの子ページだったら
                if ( body.hasClass('home') || body.hasClass('parent-pageid-138') ) {
                    if (winsize.width > 880) {
                        rowHeader[i].closest('.row-wrap').style.marginTop = winsize.width * 0.1 + rowHeader[i].clientHeight * 0.5 + 'px';
                    } else {
                        rowHeader[i].closest('.row-wrap').style.marginTop = winsize.width * 0.15 + rowHeader[i].clientHeight * 0.5 + 'px';
                    }
                } else {
                    rowHeader[i].closest('.row-wrap').style.marginTop = winsize.width * 0.012 + rowHeader[i].clientHeight * 0.5 + 'px';
                }
            } else {
                rowHeader[i].closest('.row-wrap').style.marginTop = winsize.width * 0.15 + rowHeader[i].clientHeight * 0.5 + 'px';
            }
        }
    }

    // その他イベント
    const preloadEvents = () => {
        return new Promise((resolve, reject) => {
            // UAクラスを付与
            // console.log(result.browser.name.toLowerCase());
            // console.log(result.browser.name.toLowerCase()+result.browser.major);
            document.documentElement.setAttribute('class', result.browser.name.toLowerCase()+' ver'+result.browser.major);

            // グローバルナビゲーション
            globalNav = new GlobalNav();
            globalNav.init();

            new StickyHeader();

            // カスタムスクロールバー
            psEls = document.querySelectorAll('.ps-scroll');
            if (psEls[0]) {
                psEls.forEach(el => {
                    ps = new PerfectScrollbar(el);
                });
            }

            // Ajaxコンテンツを登録
            ajxContTriggers = document.querySelectorAll('.js-ajx-trigger');
            if (ajxContTriggers[0]) {
                for (const el of ajxContTriggers) {
                    new AjaxContentLoader(el);
                }
            }

            // Ajaxページリンクを登録
            ajxPageTriggers = document.querySelectorAll('.js-ajx-page-trigger');
            if (ajxPageTriggers[0]) {
                ajaxPageLoader = new AjaxPageLoader( ajxPageTriggers );
            }

            // Gallery Modal
            let galleryItem = document.querySelectorAll('.gallery__item');
            if (galleryItem[0]) {
                for ( const el of galleryItem ) {
                    new Gallery(el);
                }
            }

            // Gallery Pagination
            const galleryPagination = document.querySelector('.gallery__pagination a');
            if (galleryPagination) {
                new GalleryAjaxLoader(galleryPagination);
            }

            resolve();
        });
    };

    // 背景動画 読み込み
    // if (bgVideo) {
    //     bgVideo.load();
    // }

    const preloadVideo = () => {
        return new Promise((resolve, reject) => {
            autoPlayVideos = document.querySelectorAll('.auto-play-video');

            if (autoPlayVideos[0]) {
                for (const el of autoPlayVideos) {
                    el.load();

                    el.addEventListener('loadeddata', () => {
                        console.log('auto play video loaded');
                        resolve();
                    });
                }
            } else {
                resolve();
            }
        });
    };

    // Intersection Observer
    let observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.intersectionRatio > 0) {
                const _mask = entry.target.querySelector('.plx__mask');
                const _img = entry.target.querySelector('.plx__img');
                if ( _mask ) {
                    TweenMax.to( _mask, 0.6, { css:{scaleX:0}, ease: Expo.easeInOut });
                }

                if ( _img ) {
                    if ( !_img.hasClass('no-scale') ) {
                        TweenMax.to( _img, 2.4, { css:{scale:1}, ease: Expo.easeOut });
                    }
                }
            }
        });
    });

    // 初期化
    const init = () => {
        return new Promise(resolve => {
            charms = document.querySelectorAll('.js-charming');
            txtCutElements = document.querySelectorAll('.js-cut');

            layout();

            // Charming
            for ( const el of charms ) {
                charming(el);
            }

            /*
             * テキストを短く
             * single.phpのパンくずリスト
             */
            for ( const el of txtCutElements ) {
                el.textContent = txtCut(el.textContent, 24, '...', '');
            }

            getPageYScroll();

            // if (!isTouch) {
            //     smoothScroll = new SmoothScroll();
            // }

            const videoModal = new VideoModal();
            videoModal.init();

            // if (bgVideo) {
            //     bgVideo.play();
            // }

            // if (autoPlayVideos[0]) {
            //     for (const el of autoPlayVideos) {
            //         el.play();
            //     }
            // }

            console.log('all loaded');

            resolve();
        });
    };

    let resizeEvent1;
    let resizeEvent2;
    let resizeEvent3;
    let resizeEvent4;
    let resizeEvent5;

    const resize = () => {
        resizeEvent1 = () => {
            return new Promise(resolve => {
                calcWinsize();

                if (psEls[0]) {
                    ps.update();
                }

                console.log('resize 1');
                resolve();
            });
        };

        resizeEvent2 = () => {
            return new Promise(resolve => {
                console.log('resize 2');
                resolve();
            });
        };

        resizeEvent3 = () => {
            return new Promise(resolve => {
                // overflow:visibleを強制しているため、カルーセルコンテナに高さを動的に付与
                if ( document.querySelectorAll('.carousel')[0] ) {
                    document.querySelectorAll('.carousel')[0].style.height = document.querySelectorAll('.services-lead__main__item')[0].clientHeight + 'px';
                }

                // Carousel
                // if ( document.querySelectorAll('.carousel')[0] ) {
                //     carousel = new Siema({
                //         selector: '.carousel',
                //         duration: 200,
                //         easing: 'ease-out',
                //         perPage: 3,
                //         startIndex: 0,
                //         draggable: true,
                //         multipleDrag: true,
                //         threshold: 20,
                //         loop: false,
                //         rtl: false,
                //         onInit: () => {
                //             // overflow:visibleを強制しているため、カルーセルコンテナに高さを動的に付与
                //             document.querySelectorAll('.carousel')[0].style.height = document.querySelectorAll('.services-lead__main__item')[0].clientHeight + 'px';
                //         },
                //         onChange: () => {},
                //     });
                // }

                console.log('resize 3');
                resolve();
            });
        };

        resizeEvent4 = () => {
            return new Promise(resolve => {
                layout();

                console.log('resize 4');
                resolve();
            });
        };

        resizeEvent5 = () => {
            return new Promise(resolve => {
                // smoothScroll.setSize();

                console.log('resize 5');
                resolve();
            });
        };

        if (isTouch) {
            window.addEventListener('orientationchange', () => {
                resizeEvent1().then(() => {
                    resizeEvent2();
                }).then(() => {
                    resizeEvent3();
                }).then(() => {
                    resizeEvent4();
                }).then(() => {
                    resizeEvent5();
                });
            });
        } else {
            window.addEventListener('resize', () => {
                resizeEvent1().then(() => {
                    resizeEvent2();
                }).then(() => {
                    resizeEvent3();
                }).then(() => {
                    resizeEvent4();
                }).then(() => {
                    resizeEvent5();
                });
            });
        }



        console.log('handle resize event');
    }


    // 完了
    const done = () => {
        const waveLines = document.querySelectorAll('.hero__title .js-wave-line span');

        mapWrap = document.getElementById('map');
        if (mapWrap) {
            initMap();
        }

        setTimeout(() => {
            tl.to('.preloader__num', 0.6, {
                opacity: 0,
                ease: Expo.easeInOut
            }).to('.preloader__progress-wrap', 0.6, {
                scaleX:0,
                ease: Expo.easeInOut,
                onComplete: () => {
                    if ( slideBoard ) {
                        slideBoard.move();
                    }
                }
            }).to('.preloader__bg--1', 1.2, {
                x:'100%',
                ease: Expo.easeInOut,
                onUpdate: () => {
                    if ( tl._last._time / tl._last._duration > 0.3 ) {
                        TweenMax.staggerTo(waveLines, 1.2, {
                            y: 0,
                            ease: Expo.easeOut
                        }, 0.05);
                    }

                    if ( tl._last._time / tl._last._duration > 0.5 ) {
                        [...document.querySelectorAll('.plx')].forEach(item => observer.observe(item));
                        [...document.querySelectorAll('.gallery__item')].forEach(item => observer.observe(item));

                        if (autoPlayVideos[0]) {
                            for (const el of autoPlayVideos) {
                                el.play();
                            }
                        }
                    }
                },
                onComplete: () => {
                    TweenMax.set(preloaderBg, {
                        x: '-100%'
                    });
                    preloader.style.display = 'none';
                }
            });
        }, 1000);

        console.log('done');
    };

    let progressCount = {value:0};
    const progressNumEl = document.querySelector('.preloader__num');
    // 進捗制御
    const progress = (p, len) => {

        // Progress Bar
        TweenMax.to( '.preloader__progress', 1, {
            x:p+'%',
            ease: Expo.easeInOut
        });

        // Num Count
        TweenMax.to( progressCount, 1, {
            value:p,
            roundProps:'value',
            onUpdate: () => {
                progressNumEl.textContent = progressCount.value;
            },
            ease: Expo.easeInOut
        });
    };

    const allProgress = (proms, progress_cb) => {
        let d = 0;
        progress_cb(0, proms.length);

        for (const p of proms) {
            p.then(() => {
                d++;
                progress_cb((d * 100) / proms.length, proms.length);
            });
        }

        return Promise.all(proms).then(() => {
            return init();
        }).then(() => {
            return resize();
        }).then(() => {
            done();
        });
    };



    allProgress([preloadImages(), preloadFonts(), preloadPage(), preloadVideo(), preloadEvents()], progress);

}
