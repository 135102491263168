/**
 * ブラウザ取得
 * @return {[type]} [description]
 */
function getBrowser() {
    var ua = window.navigator.userAgent.toLowerCase();
    var ver = window.navigator.appVersion.toLowerCase();
    var name = 'unknown';

    if (ua.indexOf('msie') != -1) {
        if (ver.indexOf('msie 6.') != -1) {
            name = 'ie6';
        } else if (ver.indexOf('msie 7.') != -1) {
            name = 'ie7';
        } else if (ver.indexOf('msie 8.') != -1) {
            name = 'ie8';
        } else if (ver.indexOf('msie 9.') != -1) {
            name = 'ie9';
        } else if (ver.indexOf('msie 10.') != -1) {
            name = 'ie10';
        } else {
            name = 'ie';
        }
    } else if (ua.indexOf('trident/7') != -1) {
        name = 'ie11';
    } else if (ua.indexOf('chrome') != -1) {
        name = 'chrome';
    } else if (ua.indexOf('safari') != -1) {
        name = 'safari';
    } else if (ua.indexOf('opera') != -1) {
        name = 'opera';
    } else if (ua.indexOf('firefox') != -1) {
        name = 'firefox';
    }

    return name;
}

/**
 * ブラウザ判定
 * @param  {[type]}  browsers [description]
 * @return {Boolean}          [description]
 */
export function isSupported(browsers) {
    let thusBrowser = getBrowser();

    for (var i = 0; i < browsers.length; i++) {
        if (browsers[i] == thusBrowser) {
            return true;
        }
    }

    return false;
}

/**
 * webkit判定
 * @return {[type]} [description]
 */
export function webkitOrNot() {
    var prefixes = 'transform webkitTransform mozTransform oTransform msTransform'.split(' ');
    var i = 0;
    var el = document.createElement('div');
    var transform;

    while (transform === undefined) {
        transform = el.style[prefixes[i]] !== undefined ? prefixes[i] : undefined;
        i += 1;
    }

    return transform;
}

/**
 * タッチデバイス判定
 * @return {[type]} [description]
 */
export function touchOrNot() {
    return window.ontouchstart === null;
}

/*
 * テキストを指定の長さにカットする
 * 使用箇所: language.js
 */
export function txtCut(str, size, suffix, onShortSuffix) {
    str = !str ? '' : str;
    size = !size ? 30 : size;
    suffix = !suffix ? '...' : suffix;
    let b = 0;
    let r = 0;
    for (let i = 0, len = str.length; i < len; i++) {
        b += str.charCodeAt(i) <= 255 ? 1 : 2;
        if (b > size) {
            return str.substr(0, i) + suffix;
        }
        r = i;
    }

    return str.substr(0, r+1) + onShortSuffix;
}

/*
 * テキストを指定の長さにカットする
 * 使用箇所: 関連コンテンツの要約
 */
function cut(str, size, suffix, onShortSuffix) {
    str = !str ? '' : str;
    size = !size ? 30 : size;
    suffix = !suffix ? '...' : suffix;
    let b = 0;
    let r = 0;
    for (let i = 0, len = str.length; i < len; i++) {
        b += str.charCodeAt(i) <= 255 ? 1 : 2;
        if (b > size) {
            return str.substr(0, i) + suffix;
        }
        r = i;
    }

    return str.substr(0, r+1) + onShortSuffix;
}

export function truncate(item, _size, _suffix, _onShortSuffix) {
    if (!item) {
        return;
    }

    let size = _size || 240;
    let suffix = _suffix || '';
    let onShortSuffix = _onShortSuffix || '';
    let str;
    let p = document.createElement('p');

    str = cut(item.textContent, size, suffix, onShortSuffix);
    item.innerHTML = null;
    p.innerHTML = str;
    item.appendChild(p);
}

/**
 * イベントの間引き処理
 */
let timer = null;
export function decimation(fn, interval) {
    clearTimeout(timer);
    timer = setTimeout(function() {
        fn();
    }, interval);
}

/**
 * 指定したURLパラメータの値を取得
 */
export function getParameterByName(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
        results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function utility() {
    Object.defineProperties( Element.prototype, {
        hasClass: {
            value: function( class_name ) {
                if ( this.classList ) {
                    return this.classList.contains( class_name );
                } else {
                    return new RegExp( '(^| )' + class_name + '( |$)', 'gi' ).test( this.className );
                }
            },
            enumerable: false,
            configurable: false,
            writable: false
        },
        addClass: {
            value: function ( class_name ) {
                if ( !this.hasClass( class_name ) ) {
                    if ( this.classList ) {
                        this.classList.add( class_name );
                    } else {
                        this.className += ' ' + class_name;
                    }
                }
            },
            enumerable: false,
            configurable: false,
            writable: false
        },
        removeClass: {
            value: function( class_name ) {
                var classes = this.className.split(' ');
                if (this.hasClass( class_name )) {
                    if (this.classList) {
                        this.classList.remove( class_name );
                    } else {
                        var existing_index = classes.indexOf( class_name );
                        classes.splice(existing_index, 1);
                        this.className = classes.join(' ');
                    }
                } else {
                    classes = null;
                }
            },
            enumerable: false,
            configurable: false,
            writable: false
        },
        toggleClass: {
            value: function( class_name ) {
                if (this.classList) {
                    this.classList.toggle( class_name );
                } else {
                    var classes = this.className.split(' ');
                    var existing_index = classes.indexOf( class_name );
                    if (existing_index >= 0) {
                        classes.splice(existing_index, 1);
                    } else {
                        classes.push( class_name );
                    }
                    this.className = classes.join(' ');
                }
            },
            enumerable: false,
            configurable: false,
            writable: false
        },
        closest: {
            value: function( selector ) {
                const matchesSelector = this.matches || this.webkitMatchesSelector || this.mozMatchesSelector || this.msMatchesSelector;
                let self = this;

                while (self) {
                    if (matchesSelector.call(self, selector)) {
                        return self;
                    } else {
                        self = self.parentElement;
                    }
                }
                return null;
            },
            enumerable: false,
            configurable: false,
            writable: false
        }
    });
}
